// components/InsuranceInfoForm.jsx
import { useState } from 'react';

const InsuranceInfoForm = ({ onChange }) => {
  const [formData, setFormData] = useState({
    nombre_aseguradora: '',
    numero_poliza: '',
    prevision_salud: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    onChange(formData);
  };

  return (
    <div className="p-4 bg-white shadow-md rounded-lg">
      <h2 className="text-lg font-semibold mb-4">Información de Seguro</h2>
      <div className="space-y-4">
        <div className="flex flex-col">
          <label htmlFor="nombre_aseguradora" className="text-gray-700 font-medium">Nombre de Aseguradora</label>
          <input
            id="nombre_aseguradora"
            name="nombre_aseguradora"
            type="text"
            value={formData.nombre_aseguradora}
            onChange={handleChange}
            className="mt-1 p-2 border border-gray-300 rounded"
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="numero_poliza" className="text-gray-700 font-medium">Número de Póliza</label>
          <input
            id="numero_poliza"
            name="numero_poliza"
            type="text"
            value={formData.numero_poliza}
            onChange={handleChange}
            className="mt-1 p-2 border border-gray-300 rounded"
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="prevision_salud" className="text-gray-700 font-medium">Previsión de Salud</label>
          <select
            id="prevision_salud"
            name="prevision_salud"
            value={formData.prevision_salud}
            onChange={handleChange}
            className="mt-1 p-2 border border-gray-300 rounded"
          >
            <option value="">Seleccione...</option>
            <option value="Isapre">Isapre</option>
            <option value="Fonasa">Fonasa</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default InsuranceInfoForm;
