import React, { useState } from 'react';

const faqs = [
  {
    question: '¿Qué pasa si no me sé mi información médica para crear mi ficha?',
    answer: 'Puedes llamar a nuestro equipo médico para recibir orientación. Ellos te guiarán para que puedas hacer una visita posterior a un centro de salud y así tener tu información actualizada. De todas formas, puedes crear la ficha con tu información básica e ir actualizándola después.',
  },
  {
    question: '¿Puedo crear cuentas para otras personas, como adultos mayores o niños?',
    answer: 'Sí, es posible crear cuentas para otras personas. Es importante tener la información a la mano. Puedes llamar a nuestro equipo médico y ellos realizarán una entrevista, además de ofrecer orientación para conseguir la información faltante en caso de ser necesario.',
  },
  {
    question: '¿Dónde podrán ver mi información?',
    answer: 'Solo tú podrás ver tu información médica. Además, los doctores verificados de los centros médicos con los cuales tenemos alianzas podrán actualizar tu ficha médica durante la consulta. Esto será útil para hacer seguimientos de salud, prepararse para un viaje, o para recibir mejor atención en caso de estar en un centro médico no habitual por una urgencia.',
  },
];

export default function FAQs() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="max-w-3xl mx-auto py-12">
      <div className="space-y-6">
        {faqs.map((faq, index) => (
          <div key={index} className="border-b border-gray-200">
            <button
              className="w-full py-4 flex justify-between items-center text-left focus:outline-none"
              onClick={() => toggleAccordion(index)}
            >
              <span className="text-lg font-semibold text-gray-900">{faq.question}</span>
              <svg
                className={`ml-2 h-6 w-6 transition-transform transform ${activeIndex === index ? 'rotate-0' : 'rotate-180'}`}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19 9l-7 7-7-7"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            {activeIndex === index && (
              <p className="mt-2 text-sm text-gray-600">{faq.answer}</p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
