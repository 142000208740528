import React from 'react';
// Importa las imágenes locales
import lorenaImg from '../images/lore2.jpg';
import nicolasImg from '../images/nico.png';
import venegasImg from '../images/alfonso1.jpg';

const people = [
  {
    name: 'Lorena Gutierrez Arab',
    role: 'Co-Founder / CEO',
    imageUrl: lorenaImg, // Usa la imagen importada
  },
  {
    name: 'Nicolás Arriaza Verreni',
    role: 'Co-Founder / CTO',
    imageUrl: nicolasImg, // Usa la imagen importada
  },
  {
    name: 'Venegas Medina',
    role: 'CFO',
    imageUrl: venegasImg, // Usa la imagen importada
  },
];

export default function Team() {
  return (
    <div className="relative bg-transparent px-6 lg:px-8">
      <div
        aria-hidden="true"
        className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
      >
        <div
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
          className="absolute inset-0 bg-gradient-to-r from-cyan-500 to-blue-500 opacity-30"
        />
      </div>
      <div className="mx-auto grid max-w-7xl gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-3 relative z-10">
        <div className="max-w-2xl">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Conoce al equipo</h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Contamos con un equipo interdisciplinario y experto en tecnología, finanzas, medicina e inteligencia artificial
          </p>
        </div>
        <ul role="list" className="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
          {people.map((person) => (
            <li key={person.name}>
              <div className="flex items-center gap-x-6">
                <div className="h-16 w-16 rounded-full overflow-hidden bg-gray-200 relative">
                  <img
                    alt={person.name}
                    src={person.imageUrl}
                    className="absolute h-full w-full object-cover"
                  />
                </div>
                <div>
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">{person.name}</h3>
                  <p className="text-sm font-semibold leading-6 text-indigo-600">{person.role}</p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
