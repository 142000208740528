import React, { useState } from 'react';

export default function CallInfoModal({ isOpen, onClose }) {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [telefono, setTelefono] = useState(''); // Nuevo estado para el teléfono
  const [errorMessage, setErrorMessage] = useState(''); // Nuevo estado para el mensaje de error

  if (!isOpen) return null;

  const handleSubmit = async () => {
    setErrorMessage(''); // Limpiar cualquier error previo

    try {
      const response = await fetch('https://api.medwise.cl/api/add-telefono/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ telefono }),
      });

      if (response.ok) {
        setIsSubmitted(true);
        setTimeout(() => {
          setIsSubmitted(false);
          onClose();
        }, 2000); // 2 segundos de animación antes de cerrar el modal
      } else {
        const data = await response.json();
        setErrorMessage(data.error || 'Ocurrió un error al enviar el número de teléfono.');
      }
    } catch (error) {
      setErrorMessage('Error de red. Por favor, inténtalo nuevamente.');
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-80 max-w-md">
        {isSubmitted ? (
          <div className="flex flex-col items-center justify-center">
            <div className="text-green-500 text-2xl font-semibold animate-bounce">
              ¡Enviado!
            </div>
          </div>
        ) : (
          <>
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-semibold">Proveer Información para Llamada</h2>
              <button
                onClick={onClose}
                className="text-gray-600 hover:text-gray-900"
                aria-label="Close"
              >
                &times;
              </button>
            </div>
            <div className="mt-4">
              <p>Por favor, proporciona tu número de teléfono y te llamaremos para obtener más información.</p>
              <input
                type="tel"
                value={telefono}
                onChange={(e) => setTelefono(e.target.value)} // Actualiza el estado del teléfono
                placeholder="Número de teléfono"
                className="mt-2 w-full p-2 border border-gray-300 rounded"
              />
            </div>
            {errorMessage && ( // Mostrar el mensaje de error si existe
              <div className="mt-4 text-red-500 text-sm">
                {errorMessage}
              </div>
            )}
            <div className="mt-6 flex justify-end">
              <button
                onClick={handleSubmit}
                className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
              >
                Enviar
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
