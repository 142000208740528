import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './components/AuthContext.js'; // Importa el AuthProvider
import Home from './pages/Home';
import Ficha from './pages/Ficha';
import FormularioPage from './pages/Formularioregistro';
import RegistroPaciente from './pages/historialmedicoform';
import ProtectedRoute from './components/ProtectedRoute'; // Importa el componente ProtectedRoute

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/ficha" element={<ProtectedRoute />}>
            <Route path="" element={<Ficha />} />
          </Route>
          <Route path="/Registro" element={<FormularioPage />} />
          <Route path="/historialmedicoform" element={<RegistroPaciente />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
