// components/formularioregistro/HistorialMedicoForm.jsx
import { useState } from 'react';

const MedicalHistoryForm = ({ onChange }) => {
  const [items, setItems] = useState([]);
  const [notas, setNotas] = useState('');

  const handleAddItem = () => {
    const newItem = {
      id: items.length,
      fecha: '',
      medico: '',
      tipoConsulta: '',
      diagnostico: '',
      tratamiento: '',
      control: '',
      archivoAdjunto: '',
    };
    const updatedItems = [...items, newItem];
    setItems(updatedItems);
    onChange({ historial_medico: updatedItems, notas_adicionales: notas });
  };

  const handleItemChange = (index, field, value) => {
    const newItems = [...items];
    newItems[index][field] = value;
    setItems(newItems);
    onChange({ historial_medico: newItems, notas_adicionales: notas });
  };

  const handleRemoveItem = (index) => {
    const newItems = items.filter((_, i) => i !== index);
    setItems(newItems);
    onChange({ historial_medico: newItems, notas_adicionales: notas });
  };

  const handleNotasChange = (e) => {
    const updatedNotas = e.target.value;
    setNotas(updatedNotas);
    onChange({ historial_medico: items, notas_adicionales: updatedNotas });
  };

  return (
    <div className="p-4 bg-white shadow-md rounded-lg">
      <h2 className="text-lg font-semibold mb-4">Historial Médico</h2>
      {items.map((item, index) => (
        <div key={item.id} className="mb-4 p-4 border border-gray-300 rounded relative">
          <button
            onClick={() => handleRemoveItem(index)}
            className="absolute top-2 right-2 text-red-500 hover:text-red-700"
          >
            &times;
          </button>
          <input
            type="text"
            placeholder="Fecha (e.g., 15/01/2024)"
            value={item.fecha}
            onChange={(e) => handleItemChange(index, 'fecha', e.target.value)}
            className="w-full p-2 border border-gray-300 rounded mb-2"
          />
          <input
            type="text"
            placeholder="Médico (e.g., Dr. Carlos Ruiz)"
            value={item.medico}
            onChange={(e) => handleItemChange(index, 'medico', e.target.value)}
            className="w-full p-2 border border-gray-300 rounded mb-2"
          />
          <input
            type="text"
            placeholder="Tipo de Consulta (e.g., General)"
            value={item.tipoConsulta}
            onChange={(e) => handleItemChange(index, 'tipoConsulta', e.target.value)}
            className="w-full p-2 border border-gray-300 rounded mb-2"
          />
          <textarea
            placeholder="Diagnóstico"
            value={item.diagnostico}
            onChange={(e) => handleItemChange(index, 'diagnostico', e.target.value)}
            className="w-full p-2 border border-gray-300 rounded mb-2"
          />
          <textarea
            placeholder="Tratamiento Recomendado"
            value={item.tratamiento}
            onChange={(e) => handleItemChange(index, 'tratamiento', e.target.value)}
            className="w-full p-2 border border-gray-300 rounded mb-2"
          />
          <textarea
            placeholder="Control y observaciones"
            value={item.control}
            onChange={(e) => handleItemChange(index, 'control', e.target.value)}
            className="w-full p-2 border border-gray-300 rounded mb-2"
          />
          <input
            type="file"
            onChange={(e) => handleItemChange(index, 'archivoAdjunto', e.target.files[0])}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
      ))}
      <button
        onClick={handleAddItem}
        className="mb-4 bg-blue-500 text-white p-2 rounded"
      >
        Agregar Ítem
      </button>
      <textarea
        placeholder="Notas adicionales..."
        value={notas}
        onChange={handleNotasChange}
        className="w-full p-2 border border-gray-300 rounded"
      />
    </div>
  );
};

export default MedicalHistoryForm;
