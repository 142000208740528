import React, { useState } from 'react';
import '../index.css';
import Herosection from '../components/Herosection';
import Featuresection from '../components/Featuresections';
import Logocloud from '../components/Logocloud';
import Pricingsection from '../components/Pricingsections';
import Team from '../components/Team';
import FAQs from '../components/Faq';
import Footer from '../components/Footer';
import RegistrationForm from '../components/RegistrationForm';


const marginStyle = {
  marginTop: '40px', // Ajusta el margen superior según tus necesidades
  marginBottom: '40px' // Ajusta el margen inferior según tus necesidades
};



function Home() {

  return (
    
    <div>
      
      <div style={marginStyle}><Herosection /></div>
      
      <div style={marginStyle}><RegistrationForm /></div>
      <div style={marginStyle}><Featuresection /></div>
      <div style={marginStyle}><Logocloud /></div>
      <div style={marginStyle}><Pricingsection /></div>
      <div style={marginStyle}><Team /></div>
      <div style={marginStyle}><FAQs /></div>
      <div style={marginStyle}><Footer /></div>
    </div>
  );
}

export default Home;
