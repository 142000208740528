import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Ficha = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // Obtiene el rut de la URL
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const rut = queryParams.get('rut');

  useEffect(() => {
    const fetchUserData = async () => {
      console.log('Fetching user data for RUT:', rut);  // Depuración del RUT

      try {
        const response = await fetch(`https://api.medwise.cl/api/usuarios/?rut=${rut}`);
        console.log('API Response Status:', response.status);  // Estado de la respuesta

        if (!response.ok) {
          throw new Error('Error al obtener los datos del usuario');
        }
        
        const data = await response.json();
        console.log('API Response Data:', data);  // Datos obtenidos

        setUser(data[0]); // Asumiendo que el resultado es un array con un único objeto
        setLoading(false);
      } catch (error) {
        console.error('Error fetching user data:', error.message);  // Error de la API
        setError(error.message);
        setLoading(false);
      }
    };

    if (rut) {
      fetchUserData();
    }
  }, [rut]);

  if (loading) {
    return <p>Cargando datos...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    user && (
      <div className="min-h-screen bg-gray-100 p-6">
        <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-lg p-6">
          <h2 className="text-2xl font-bold mb-6 text-center text-gray-800">Ficha Médica - {user.nombre_completo}</h2>

          <div className="bg-gray-50 p-4 rounded-lg shadow-sm mb-6">
            <h3 className="text-xl font-semibold mb-4 text-gray-700 text-center">Información del Paciente</h3>
            <p><strong>Nombre:</strong> {user.nombre_completo}</p>
            <p><strong>Edad:</strong> {user.edad}</p>
            <p><strong>Género:</strong> {user.genero || 'No especificado'}</p>
            <p><strong>Teléfono:</strong> {user.telefono}</p>
            <p><strong>Correo Electrónico:</strong> {user.correo_electronico}</p>
            <p><strong>Dirección:</strong> {user.direccion}</p>
            <p><strong>Región:</strong> {user.region}</p>
            <p><strong>Comuna:</strong> {user.comuna}</p>
            <p><strong>Previsión de Salud:</strong> {user.prevision_salud}</p>
            <p><strong>Nombre de Emergencia:</strong> {user.nombre_emergencia}</p>
            <p><strong>Teléfono de Emergencia:</strong> {user.telefono_emergencia}</p>
            <p><strong>RUT:</strong> {user.rut}</p>
            <p><strong>Vacunas:</strong> {user.vacunas}</p>
          </div>

          <div className="bg-gray-50 p-4 rounded-lg shadow-sm mb-6">
            <h3 className="text-xl font-semibold mb-4 text-gray-700 text-center">Historial Médico</h3>
            <ul className="space-y-4">
              {user.historial_medico.map((record, index) => (
                <li key={index} className="bg-white p-4 rounded-lg shadow-md">
                  <h4 className="text-lg font-semibold mb-2">{record.tipo_consulta} - {record.fecha_consulta}</h4>
                  <p><strong>Médico:</strong> {record.medico}</p>
                  <p><strong>Diagnóstico:</strong> {record.diagnostico}</p>
                  <p><strong>Tratamiento Recomendado:</strong> {record.tratamiento_recomendado}</p>
                  {record.archivo_adjunto && (
                    <a
                      href={record.archivo_adjunto}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 hover:underline mt-2 inline-block"
                    >
                      Ver archivo adjunto
                    </a>
                  )}
                </li>
              ))}
            </ul>
          </div>

          <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
            <h3 className="text-xl font-semibold mb-4 text-gray-700 text-center">Notas Adicionales</h3>
            <p>{user.notas_adicionales || 'No hay notas adicionales'}</p>
          </div>
        </div>
      </div>
    )
  );
};

export default Ficha;
