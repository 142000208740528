import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext.js'; // Importa el hook useAuth

const LoginModal = ({ isOpen, onClose }) => {
  const [rut, setRut] = useState('');
  const [documentNumber, setDocumentNumber] = useState('');
  const [error, setError] = useState('');
  const [authError, setAuthError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth(); // Obtén la función de login del contexto

  const validateRUT = (rut) => {
    const rutRegex = /^\d{1,8}-[\dkK]$/;
    return rutRegex.test(rut);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!validateRUT(rut)) {
      setError('RUT incorrecto. Debe tener el formato 12345678-9');
      return;
    }

    setLoading(true);
    setError('');
    setAuthError('');

    try {
      const response = await fetch('https://api.medwise.cl/api/login/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: rut,
          password: documentNumber,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Error de autenticación');
      }

      const data = await response.json();
      // Guardar el estado de autenticación en el contexto
      login({ rut, token: data.token }); // Asegúrate de adaptar los datos de usuario según tu API

      // Redirigir al usuario a /ficha con el rut como parámetro
      navigate(`/ficha?rut=${rut}`);
    } catch (error) {
      setAuthError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    isOpen && (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white rounded-lg p-6 w-full max-w-md">
          <h2 className="text-xl font-bold mb-4 text-center">Login</h2>
          <form onSubmit={handleLogin} className="space-y-4">
            <div>
              <label htmlFor="rut" className="block text-sm font-medium text-gray-700">RUT</label>
              <input
                type="text"
                id="rut"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                value={rut}
                onChange={(e) => setRut(e.target.value)}
                placeholder="12345678-9"
                required
              />
              {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
            </div>
            <div>
              <label htmlFor="documentNumber" className="block text-sm font-medium text-gray-700">Número de Documento</label>
              <input
                type="password"
                id="documentNumber"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                value={documentNumber}
                onChange={(e) => setDocumentNumber(e.target.value)}
                required
              />
            </div>
            {authError && <p className="text-red-500 text-sm mt-1">{authError}</p>}
            <button
              type="submit"
              className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition-colors"
              disabled={loading}
            >
              {loading ? 'Cargando...' : 'Iniciar Sesión'}
            </button>
          </form>
          <button
            onClick={onClose}
            className="mt-4 w-full bg-gray-200 text-gray-700 py-2 px-4 rounded-md hover:bg-gray-300 transition-colors"
          >
            Cerrar
          </button>
        </div>
      </div>
    )
  );
};

export default LoginModal;
