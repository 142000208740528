import { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Importa useNavigate

const RegistrationForm = ({ isOpen, onClose, onSelectOption }) => {
  const navigate = useNavigate(); // Inicializa useNavigate

  const handleSelectOption = (option) => {
    if (option === 'register') {
      navigate('/Registro'); // Navega a la ruta Registro
    } else {
      onSelectOption(option);
    }
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="relative w-full max-w-md rounded bg-white p-6">
        <h2 className="text-lg font-bold">Registro</h2>
        <div className="mt-2">
          {/* Botones para seleccionar las opciones */}
          <div className="flex flex-col gap-4">
            <button
              className="rounded-md bg-blue-900 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-800"
              onClick={() => handleSelectOption('register')} // Llama a handleSelectOption con 'register'
            >
              Llenar formulario
            </button>
            <button
              className="rounded-md bg-blue-900 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-800"
              onClick={() => handleSelectOption('call')}
            >
              Prefiero que me llamen
            </button>
          </div>
        </div>
        <div className="mt-4 flex justify-end">
          <button
            className="px-4 py-2 bg-blue-900 text-white rounded"
            onClick={onClose}
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  );
};

export default RegistrationForm;
