import React from 'react';

export default function Footer() {
  return (
    <footer className="bg-gray-800 text-white">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-4">
          <div className="space-y-4">
            <h2 className="text-xl font-semibold">Contacto</h2>
            <p className="text-gray-300">Teléfono: +56 9 8196 8616</p>
            <p className="text-gray-300">Correo electrónico: medwisechile@gmail.com</p>
          </div>
        </div>
      </div>
    </footer>
  );
}
