// components/ExperienceForm.jsx
import { useState } from 'react';

const ExperienceForm = ({ onChange }) => {
  const [formData, setFormData] = useState({
    experiencia_previas: false,
    comentarios_experiencia: '',
    dispositivo: '',
    conexion_internet: '',
    idioma_preferencia: ''
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({ ...prev, [name]: type === 'checkbox' ? checked : value }));
    onChange(formData);
  };

  return (
    <div className="p-4 bg-white shadow-md rounded-lg">
      <h2 className="text-lg font-semibold mb-4">Experiencia y Preferencias</h2>
      <div className="space-y-4">
        <div className="flex items-center">
          <input
            id="experiencia_previas"
            name="experiencia_previas"
            type="checkbox"
            checked={formData.experiencia_previas}
            onChange={handleChange}
            className="h-4 w-4 text-blue-600 border-gray-300 rounded"
          />
          <label htmlFor="experiencia_previas" className="ml-2 text-gray-700">¿Experiencia previa con consultas médicas en línea?</label>
        </div>
        <div className="flex flex-col">
          <label htmlFor="comentarios_experiencia" className="text-gray-700 font-medium">Comentarios sobre la experiencia</label>
          <textarea
            id="comentarios_experiencia"
            name="comentarios_experiencia"
            value={formData.comentarios_experiencia}
            onChange={handleChange}
            className="mt-1 p-2 border border-gray-300 rounded"
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="dispositivo" className="text-gray-700 font-medium">Tipo de Dispositivo</label>
          <input
            id="dispositivo"
            name="dispositivo"
            type="text"
            value={formData.dispositivo}
            onChange={handleChange}
            className="mt-1 p-2 border border-gray-300 rounded"
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="conexion_internet" className="text-gray-700 font-medium">Conexión a Internet</label>
          <input
            id="conexion_internet"
            name="conexion_internet"
            type="text"
            value={formData.conexion_internet}
            onChange={handleChange}
            className="mt-1 p-2 border border-gray-300 rounded"
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="idioma_preferencia" className="text-gray-700 font-medium">Idioma de Preferencia</label>
          <select
            id="idioma_preferencia"
            name="idioma_preferencia"
            value={formData.idioma_preferencia}
            onChange={handleChange}
            className="mt-1 p-2 border border-gray-300 rounded"
          >
            <option value="">Seleccione...</option>
            <option value="Español">Español</option>
            <option value="Inglés">Inglés</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default ExperienceForm;
