import { ClockIcon, UserCircleIcon, CloudArrowUpIcon, DevicePhoneMobileIcon } from '@heroicons/react/24/outline';

const features = [
  {
    name: 'Inter-Operabilidad',
    description:
      'Cualquier medico o entidad de salud que lo atienda, puede acceder a su info en caso de que sea necesario, incluyendo clinicas, hospitales y ambulancias',
    icon: CloudArrowUpIcon,
  },
  {
    name: 'Tiempos de espera',
    description:
      'Para el traspaso de exmanes superamos al sistema actual de salud, de seis meses a un par de segundos',
    icon: ClockIcon,
  },
  {
    name: 'Portabilidad',
    description:
      'Puedes llevar tu informacion a donde quieras, incluido para viajes en el extranjero',
    icon: DevicePhoneMobileIcon,
  },
  {
    name: 'Evitar examenes duplicados',
    description:
      'Al tener tu historial medico digitalizado no es necesario volver a hacer un examen por la perdida de los resultados o el olvido de este, sobre todo en personas mayores',
    icon: UserCircleIcon,
  },
];

export default function Featuresection() {
  return (
    <div className="bbg-gradient-to-r from-cyan-500 to-blue-500">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-600">Ficha Clinica</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Gracias a la tecnología buscamos mejorar tu salúd
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Con el registro de tu informacion medica logramos los siguientes objetivos:
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                    <feature.icon aria-hidden="true" className="h-6 w-6 text-white" />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
