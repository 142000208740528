import { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Importar useNavigate
import PersonalInfoForm from '../components/formularioregistro/PersonalInfoForm';
import MedicalHistoryForm from '../components/formularioregistro/HistorialMedicoForm';
import InsuranceInfoForm from '../components/formularioregistro/AseguradoraForm';
import ExperienceForm from '../components/formularioregistro/ExperienciaForm';

const RegistroPaciente = () => {
  const [formData, setFormData] = useState({
    nombre_completo: '',
    fecha_nacimiento: '',
    edad: '',
    telefono: '',
    correo_electronico: '',
    direccion: '',
    region: '',
    comuna: '',
    nombre_aseguradora: '',
    numero_poliza: '',
    prevision_salud: '',
    experiencia_previas: false,
    comentarios_experiencia: '',
    dispositivo: '',
    conexion_internet: '',
    idioma_preferencia: '',
    nombre_emergencia: '',
    telefono_emergencia: '',
    rut: '',
    numero_documento: '',
    vacunas: '',
    historial_medico: [], // Asegúrate de que sea un array de objetos con la estructura correcta
  });

  const [isLoading, setIsLoading] = useState(false); // Estado para bloquear el botón
  const [successMessage, setSuccessMessage] = useState(''); // Estado para la notificación
  const navigate = useNavigate(); // Inicializar el hook useNavigate

  const handleFormChange = (data) => {
    setFormData((prev) => ({ ...prev, ...data }));
  };

  const generateRandomPassword = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()';
    let password = '';
    for (let i = 0; i < 10; i++) {
      password += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return password;
  };

  const registerUserWithCognito = async (userData) => {
    try {
      const response = await fetch('https://api.medwise.cl/api/register/', { // Cambia la URL según sea necesario
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Asegúrate de enviar datos como JSON
        },
        body: JSON.stringify(userData), // Convierte los datos del formulario a formato JSON
      });

      if (response.ok) {
        const result = await response.json();
        console.log('Registro exitoso en Cognito:', result);
        return true; // Devuelve true si el registro es exitoso
      } else {
        console.error('Error al registrar en Cognito:', response.statusText);
        alert('Hubo un error al registrar en Cognito. Por favor, inténtalo de nuevo.');
        return false;
      }
    } catch (error) {
      console.error('Error en la solicitud a Cognito:', error);
      alert('Hubo un problema con la solicitud a Cognito. Por favor, revisa tu conexión y vuelve a intentarlo.');
      return false;
    }
  };

  const saveFormDataToDatabase = async (data) => {
    try {
      const response = await fetch('https://api.medwise.cl/api/usuarios/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Asegúrate de enviar datos como JSON
        },
        body: JSON.stringify(data), // Convierte los datos del formulario a formato JSON
      });

      if (response.ok) {
        const result = await response.json();
        console.log('Datos guardados en la base de datos:', result);
        return true; // Retornar true si el guardado es exitoso
      } else {
        console.error('Error al guardar en la base de datos:', response.statusText);
        alert('Hubo un error al guardar en la base de datos. Por favor, inténtalo de nuevo.');
        return false;
      }
    } catch (error) {
      console.error('Error en la solicitud de guardar:', error);
      alert('Hubo un problema con la solicitud de guardado. Por favor, revisa tu conexión y vuelve a intentarlo.');
      return false;
    }
  };

  const handleRegister = async () => {
    setIsLoading(true); // Bloquear el botón al iniciar el proceso
    const randomPassword = generateRandomPassword(); // Genera una contraseña aleatoria
    const cognitoData = {
      username: formData.rut, // Usa RUT como username
      password: randomPassword, // Contraseña temporal aleatoria
      email: formData.correo_electronico, // Correo electrónico
      newpassword: formData.numero_documento, // Número de documento como nueva contraseña
    };

    // Primero, registra al usuario en Cognito
    const isCognitoRegistered = await registerUserWithCognito(cognitoData);
    if (!isCognitoRegistered) {
      setIsLoading(false);
      return; // Si el registro en Cognito falla, detén el proceso
    }

    // Si el registro en Cognito es exitoso, guarda los datos completos en la base de datos
    const dataToSave = {
      ...formData,
      edad: parseInt(formData.edad, 10), // Convertir la edad a un número
      historial_medico: formData.historial_medico.map((item) => ({
        fecha_consulta: item.fecha, // Cambiar 'fecha' a 'fecha_consulta'
        medico: item.medico,
        tipo_consulta: item.tipoConsulta, // Cambiar 'tipoConsulta' a 'tipo_consulta'
        diagnostico: item.diagnostico,
        tratamiento_recomendado: item.tratamiento, // Cambiar 'tratamiento' a 'tratamiento_recomendado'
        archivo_adjunto: item.archivoAdjunto, // Cambiar 'archivoAdjunto' a 'archivo_adjunto'
      })),
    };

    const isSaved = await saveFormDataToDatabase(dataToSave);
    if (isSaved) {
      setSuccessMessage('Registro guardado con éxito.'); // Mostrar mensaje de éxito
      setTimeout(() => {
        setSuccessMessage(''); // Ocultar el mensaje de éxito después de 3 segundos
        navigate('/#'); // Redirigir a la raíz después de 3 segundos
      }, 3000);
    }
    setIsLoading(false); // Desbloquear el botón
  };

  return (
    <div className="container mx-auto p-8">
      <h1 className="text-2xl font-bold mb-8">Registro de Paciente</h1>
      {successMessage && (
        <div className="bg-green-500 text-white px-4 py-2 rounded mb-4">
          {successMessage}
        </div>
      )}
      <div className="space-y-8">
        <PersonalInfoForm onChange={handleFormChange} />
        <MedicalHistoryForm onChange={handleFormChange} />
        <InsuranceInfoForm onChange={handleFormChange} />
        <ExperienceForm onChange={handleFormChange} />
      </div>
      <div className="mt-8">
        <button
          onClick={handleRegister}
          className={`bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
          disabled={isLoading} // Deshabilitar el botón cuando isLoading es true
        >
          {isLoading ? 'Guardando...' : 'Registrar'}
        </button>
      </div>
    </div>
  );
};

export default RegistroPaciente;
