import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const HistorialMedicoForm = () => {
  const location = useLocation();
  const navigate = useNavigate(); // Hook para redirección
  const { formData } = location.state || {};

  const [historialMedico, setHistorialMedico] = useState([
    {
      titulo: '',
      fecha: '',
      medico: '',
      tipo_consulta: '',
      diagnostico: '',
      tratamiento_recomendado: '',
      detalles: '',
      archivo: ''
    }
  ]);

  const [jsonToShow, setJsonToShow] = useState(null);

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newHistorialMedico = [...historialMedico];
    newHistorialMedico[index][name] = value;
    setHistorialMedico(newHistorialMedico);
  };

  const handleAddHistorial = () => {
    setHistorialMedico([...historialMedico, {
      titulo: '',
      fecha: '',
      medico: '',
      tipo_consulta: '',
      diagnostico: '',
      tratamiento_recomendado: '',
      detalles: '',
      archivo: ''
    }]);
  };

  const handleRemoveHistorial = (index) => {
    const newHistorialMedico = historialMedico.filter((_, i) => i !== index);
    setHistorialMedico(newHistorialMedico);
  };

  const handleSubmit = () => {
    // Armar el JSON para el POST
    const postData = {
      ...formData,
      historial_medico: historialMedico
    };

    // Mostrar el JSON en la interfaz
    setJsonToShow(postData);

    // Simulación de un llamado exitoso a la API
    setTimeout(() => {
      toast.success('Datos enviados exitosamente!', {
        onClose: () => navigate('/'), // Redirigir después de mostrar la notificación
        autoClose: 2000, // Tiempo en milisegundos antes de cerrar la notificación
      });
    }, 1000);
  };

  return (
    <div className="p-4 max-w-lg mx-auto">
      <ToastContainer /> {/* Contenedor de notificaciones */}
      <h1 className="text-2xl font-bold mb-4">Historial Médico</h1>
      <div className="mb-4">
        {formData ? (
          <div>
            <h2 className="text-xl font-semibold mb-2">Datos Recibidos:</h2>
            <pre className="bg-gray-100 p-2 rounded-md">{JSON.stringify(formData, null, 2)}</pre>
          </div>
        ) : (
          <p>No se recibieron datos del formulario.</p>
        )}
      </div>
      {historialMedico.map((item, index) => (
        <div key={index} className="mb-4 border p-4 rounded-md shadow-sm">
          <h2 className="text-lg font-semibold mb-2">Consulta {index + 1}</h2>
          <input
            type="text"
            name="titulo"
            value={item.titulo}
            onChange={(event) => handleInputChange(index, event)}
            placeholder="Título"
            className="w-full p-2 mb-2 border rounded-md"
          />
          <input
            type="text"
            name="fecha"
            value={item.fecha}
            onChange={(event) => handleInputChange(index, event)}
            placeholder="Fecha (dd/mm/aaaa)"
            className="w-full p-2 mb-2 border rounded-md"
          />
          <input
            type="text"
            name="medico"
            value={item.medico}
            onChange={(event) => handleInputChange(index, event)}
            placeholder="Médico"
            className="w-full p-2 mb-2 border rounded-md"
          />
          <input
            type="text"
            name="tipo_consulta"
            value={item.tipo_consulta}
            onChange={(event) => handleInputChange(index, event)}
            placeholder="Tipo de Consulta (General/Especialista)"
            className="w-full p-2 mb-2 border rounded-md"
          />
          <input
            type="text"
            name="diagnostico"
            value={item.diagnostico}
            onChange={(event) => handleInputChange(index, event)}
            placeholder="Diagnóstico"
            className="w-full p-2 mb-2 border rounded-md"
          />
          <input
            type="text"
            name="tratamiento_recomendado"
            value={item.tratamiento_recomendado}
            onChange={(event) => handleInputChange(index, event)}
            placeholder="Tratamiento Recomendado"
            className="w-full p-2 mb-2 border rounded-md"
          />
          <textarea
            name="detalles"
            value={item.detalles}
            onChange={(event) => handleInputChange(index, event)}
            placeholder="Detalles Adicionales"
            className="w-full p-2 mb-2 border rounded-md"
            rows="4"
          />
          <input
            type="text"
            name="archivo"
            value={item.archivo}
            onChange={(event) => handleInputChange(index, event)}
            placeholder="Archivo (URL)"
            className="w-full p-2 mb-2 border rounded-md"
          />
          <button
            type="button"
            onClick={() => handleRemoveHistorial(index)}
            className="bg-red-500 text-white py-1 px-2 rounded-md shadow-sm hover:bg-red-600"
          >
            Eliminar Consulta
          </button>
        </div>
      ))}
      <button
        type="button"
        onClick={handleAddHistorial}
        className="w-full bg-blue-500 text-white py-2 px-4 rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mb-4"
      >
        Agregar Consulta
      </button>
      <button
        type="button"
        onClick={handleSubmit}
        className="w-full bg-green-500 text-white py-2 px-4 rounded-md shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
      >
        Enviar Datos
      </button>
      {jsonToShow && (
        <div className="mt-4 p-4 border rounded-md shadow-sm">
          <h2 className="text-xl font-semibold mb-2">JSON Final:</h2>
          <pre className="bg-gray-100 p-2 rounded-md">{JSON.stringify(jsonToShow, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default HistorialMedicoForm;
