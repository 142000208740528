import React from 'react';

import aws from '../images/aws.jpg'
import hubprovi from '../images/hubprovidencia.jpg'
import openbauchef from '../images/openbauchef.png'
import corfo from '../images/corfo.png'
import startupchile from '../images/startup-chile.png' // Asegúrate de reemplazar 'nuevaImagen.jpg' por el nombre real de tu archivo


export default function Logocloud() {
  return (
    <div className="relative bg-transparent px-6 pt-14 lg:px-8">
      <div
        aria-hidden="true"
        className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
      >
        <div
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
          className="absolute inset-0 bg-gradient-to-r from-indigo-500 ... opacity-30"
        />
      </div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8 relative z-10">
        <h2 className="text-center text-lg font-semibold leading-8 text-gray-900">
          Contamos con el apoyo de:
        </h2>
        <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
          <img
            alt="Transistor"
            src={aws}
            width={158}
            height={48}
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
          />
          <img
            alt="Reform"
            src={corfo}
            width={158}
            height={48}
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
          />
          <img
            alt="Tuple"
            src={hubprovi}
            width={158}
            height={48}
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
          />
          <img
            alt="SavvyCal"
            src={openbauchef}
            width={158}
            height={48}
            className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
          />
          <img
            alt="Startupchile"
            src={startupchile}
            width={178}
            height={68}
            className="col-span-2 max-h-32 w-full object-contain lg:col-span-1"
          />
        </div>
      </div>
    </div>
  );
}
