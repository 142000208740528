// components/PersonalInfoForm.jsx
import { useState } from 'react';

const PersonalInfoForm = ({ onChange }) => {
  const [formData, setFormData] = useState({
    nombre_completo: '',
    fecha_nacimiento: '',
    telefono: '',
    correo_electronico: '',
    direccion: '',
    region: '',
    comuna: '',
    nombre_emergencia: '',
    telefono_emergencia: '',
    edad: '',
    vacunas: '',
    rut: '',
    numero_documento: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    onChange(formData);
  };

  return (
    <div className="p-4 bg-white shadow-md rounded-lg">
      <h2 className="text-lg font-semibold mb-4">Información Personal</h2>
      <div className="space-y-4">
        {Object.keys(formData).map((key) => (
          <div key={key} className="flex flex-col">
            <label htmlFor={key} className="text-gray-700 font-medium">{key.replace('_', ' ')}</label>
            <input
              id={key}
              name={key}
              type={key === 'fecha_nacimiento' ? 'date' : 'text'}
              value={formData[key]}
              onChange={handleChange}
              className="mt-1 p-2 border border-gray-300 rounded"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PersonalInfoForm;
